import React, { useState } from 'react';
import Header from './Header';
import Topics from './Topics';
import { userFromJwtResponse } from './authUtils';
import { Grid } from '@mui/material';
import { useGoogleAuth } from './useGoogleAuth';
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  const handleLogin = async (response) => {
    const user = await userFromJwtResponse(response);
    setUser(user);
  };

  useGoogleAuth(user, handleLogin);

  return (
    <div className="App">
      <Header user={user} />
      <Grid container>
        <Topics user={user} />
      </Grid>
    </div>
  );
}

export default App;
