import { auth } from './firebase';
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";

const base64UrlDecode = (str) => {
    // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    str = str.replace('-', '+').replace('_', '/');
    while (str.length % 4) {
        str += '=';
    }
    return decodeURIComponent(atob(str).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

const decodeJwtResponse = (token) => {
    // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    const parts = token.split('.');
    if (parts.length !== 3) {
        throw new Error('JWT token has an unexpected number of parts');
    }
    
    const payload = parts[1];
    const decodedPayload = base64UrlDecode(payload);
    return JSON.parse(decodedPayload);
};

const firebaseAuth = async (response) => {
    // https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
    try {
        var googleIdToken = response.credential;
        console.log('Google ID Token', googleIdToken);
        const credential = GoogleAuthProvider.credential(googleIdToken);
        console.log('Credential', credential);
        const result = await signInWithCredential(auth, credential);
        console.log('signInWithCredential Result', result);
    } catch (error) {
        console.log('signInWithCredential Error', error);
    }
};

export const userFromJwtResponse = async (response) => {
    // https://developers.google.com/identity/sign-in/web/reference#googleauthissignedinget
    const responsePayload = decodeJwtResponse(response.credential);
    await firebaseAuth(response); // auth firebase user
    return {
        id: responsePayload.sub,
        name: responsePayload.name,
        email: responsePayload.email,
        picture: responsePayload.picture
    };
};