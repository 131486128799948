import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { addDoc, collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from './firebase'; // assuming you have a firebase setup
import { Add, ArrowDownward, ArrowUpward, PriorityHigh } from "@mui/icons-material";

function Topic({ user, topic }) {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false); // Controls the visibility of the dialog
    const [newItemName, setNewItemName] = useState(""); // Holds the new item's name
    const [newItemDetails, setNewItemDetails] = useState(""); // Holds the new item's details

    useEffect(() => {
        if (topic) {
            const q = query(collection(db, "items"), where("topicId", "==", topic.id));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const updatedItems = [];
                querySnapshot.forEach((doc) => {
                    updatedItems.push({ id: doc.id, ...doc.data() });
                });
                setItems(updatedItems);
            });

            return () => unsubscribe(); // Detach listener when the component is unmounted
        }
    }, [topic]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateItem = async () => {
        if (!newItemName.trim()) return; // Prevent adding empty names
        try {
            await addDoc(collection(db, "items"), {
                name: newItemName,
                details: newItemDetails,
                topicId: topic.id,
                createdAt: new Date() // or firebase.firestore.FieldValue.serverTimestamp()
            });
            setNewItemName("");
            setNewItemDetails("");
            handleClose();
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handlePriorityUp = async (id) => {
        console.log('handlePriorityUp', id);
    }

    const handlePriorityDown = async (id) => {
        console.log('handlePriorityDown', id);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                rows={items}
                columns={[
                    {
                        field: 'priority',
                        headerName: ' ',
                        renderHeader: () => (
                            <PriorityHigh fontSize="small" />
                        ),
                        width: 35,
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1,
                        editable: true,
                    },
                    {
                        field: 'details',
                        headerName: 'Details',
                        flex: 3,
                        editable: true,
                    },
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => (
                            <>
                            <ArrowUpward
                                onClick={() => handlePriorityUp(params.id)}
                                style={{ cursor: 'pointer' }}
                            />
                            <ArrowDownward
                                onClick={() => handlePriorityDown(params.id)}
                                style={{ cursor: 'pointer' }}
                            />
                            </>
                        ),
                        width: 70,
                    }
                ]}
                autoHeight
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 2 }}>
                <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
                    Add Item
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Item</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="itemName"
                        label="Item Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="itemDetails"
                        label="Item Details"
                        type="text"
                        fullWidth
                        multiline
                        variant="standard"
                        value={newItemDetails}
                        onChange={(e) => setNewItemDetails(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCreateItem}>Add</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Topic;