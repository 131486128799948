import { useEffect, useState } from "react";
import Topic from "./Topic";
import TopicAdder from "./TopicAdder";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { Box, Tabs, Tab, Grid } from "@mui/material";

function Topics({ user }) {
    const [activeTopicId, setActiveTopicId] = useState(null);
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        let unsubscribeTopics = null;
        if (user) {
            const q = query(collection(db, "topics"), where("userIDs", "array-contains", user.id));
            unsubscribeTopics = onSnapshot(q, (querySnapshot) => {
                const newTopics = [];
                querySnapshot.forEach((doc) => {
                    newTopics.push({ id: doc.id, ...doc.data() });
                });
                setTopics(newTopics);
            });
        }
        return () => {
            unsubscribeTopics && unsubscribeTopics();
        };
    }, [user]);

    useEffect(() => {
         // Set the first topic as the active topic if none is set
        if (topics && topics.length > 0 && !activeTopicId) {
            setActiveTopicId(topics[0].id);
        }
    }, [topics, activeTopicId]);

    return (
        <>
        <Grid item xs={12}>
            <Tabs
                value={activeTopicId || "add"}
                onChange={(e, newTopicId) => setActiveTopicId(newTopicId)}
                variant="scrollable"
                scrollButtons="auto"
            >
                {topics.map((topic) => (
                    <Tab key={topic.id} label={topic.name} value={topic.id} />
                ))}
                <Tab key="add" label="New Topic" value="add" />
            </Tabs>
        </Grid>
        <Grid item xs={12}>
            <Box display="flex" alignItems="center" gap={2} p={2}>
                {activeTopicId === "add" && (
                    <TopicAdder user={user} setActiveTopicId={setActiveTopicId} />
                )}
                {activeTopicId !== "add" && (
                    <Topic
                        user={user}
                        topic={topics.find((topic) => topic.id === activeTopicId)}
                    />
                )}
            </Box>
        </Grid>
        </>
    );
}

export default Topics;