import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';
import { Button, TextField } from '@mui/material';

function TopicAdder({ user, setActiveTopicId }) {
    const [newTopicName, setNewTopicName] = useState('');

    const createTopic = async () => {
        if (newTopicName) {
            try {
                console.assert(user, "User must be logged in to create a new topic");
                console.assert(user.id, "User must have an ID to create a new topic");
                const topicRef = await addDoc(collection(db, "topics"), {
                    name: newTopicName,
                    userIDs: [user.id]
                });
                console.log("Topic written with ID: ", topicRef.id);
                setNewTopicName('');
                setActiveTopicId(topicRef.id);
            } catch (error) {
                console.error('Error creating new topic', error);
            }
        }
    }

    return (
        <>
        <TextField
            id="newTopicName"
            label="New Topic Name"
            value={newTopicName}
            onChange={(e) => setNewTopicName(e.target.value)}
            fullWidth
        />
        <Button
            variant="contained"
            onClick={createTopic}
            disabled={!newTopicName.trim()}
        >
            Create Topic
        </Button>
        </>
    );
}

export default TopicAdder;