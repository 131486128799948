import React from 'react';
import { AppBar, Toolbar, Typography, Avatar } from '@mui/material';

function Header({ user }) {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          decide.best
        </Typography>
        {user ? (
          <Avatar alt={user.name} src={user.picture} />
        ) : (
            <>
                <div id="g_id_onload"
                    data-client_id="879707814688-ofge838ugu0i0hkebnfm2sbfd1o626jp.apps.googleusercontent.com"
                    data-context="signin"
                    data-ux_mode="popup"
                    data-callback="handleLogin"
                    data-auto_select="true"
                    data-itp_support="true">
                </div>
                <div className="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-text="signin_with"
                    data-size="large"
                    data-logo_alignment="left">
                </div>
            </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
