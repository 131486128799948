import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnQyv7HPALwfGVOhcC_qnqH6aFN53RyyA",
  authDomain: "runoff-383822.firebaseapp.com",
  projectId: "runoff-383822",
  storageBucket: "runoff-383822.appspot.com",
  messagingSenderId: "879707814688",
  appId: "1:879707814688:web:c71a4665dbd6c5c798b545",
  measurementId: "G-EZRGNCG82M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, analytics, auth, db };
