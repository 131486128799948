import { useEffect } from 'react';

export const useGoogleAuth = (user, handleLogin) => {
  useEffect(() => {
    window.handleLogin = handleLogin;
    
    window.google.accounts.id.initialize({
        client_id: "879707814688-ofge838ugu0i0hkebnfm2sbfd1o626jp.apps.googleusercontent.com",
        callback: window.handleLogin,
        auto_select: true
    });
    
    if (!user) {
      window.google.accounts.id.prompt();
    }

    return () => {
      window.handleLogin = null;
    };
  }, [user, handleLogin]);
};
